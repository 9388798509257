<template>
  <div id="mian">
    <div>
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>角色名称</i>
            <Input v-model="query.keyword" suffix="ios-search" size="large" maxlength="128" placeholder="角色名称" />
          </div>
          <div class="itembtn">
            <Button class="searchBt" @click="getLists" style="margin-left:20px;">查询</Button>
            <Button class="addDetailBt" @click="addShow">新增角色</Button>
          </div>
        </div>
      </div>
      <Table row-key
          class="tab1"
          :columns="tableColumns"
          :data="tableData"
          :loading="isLoading">
          <template slot="auth" slot-scope="{ row }">
            <button class="lianjie" @click="getDetail(row, true)">查看</button>
          </template>
          <template slot="action" slot-scope="{ row }">
            <button v-auth="'BASIC:SYSTEM:ROLES/EDIT'" class="lianjie" @click="getDetail(row)">编辑</button>
            <LevelButton btnTitle="删除" :auth="'BASIC:SYSTEM:ROLES/DELETE'" @confirm="delRole(row)"></LevelButton>
          </template>
      </Table>
      <Modal v-model="addUserShow" :title="editShow === 0 ? '新增角色' : editShow === 1 ? '修改角色' : '查看角色'" closable width="490px" @on-cancel="addUserShow = false">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>角色名称</div>
            <Input
              :disabled="roleDisable"
              v-model="formData.roleName"
              maxlength="128"
              placeholder="输入角色名称"
              size="large"
              tabindex="1"
            ></Input>
            <div class="overAccount_ul_title"><i>*</i>权限明细</div>
            <el-tree
              ref="menuTree"
              :data="roleLists"
              :default-checked-keys="defaultRole"
              :props="roleProps"
              node-key="value"
              show-checkbox
              @check="roleChange"
              accordion
            >
            </el-tree>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title">角色概述</div>
            <Input
              :disabled="roleDisable"
              v-model="formData.roleDesc"
              maxlength="128"
              placeholder="输入角色概述"
              size="large"
              tabindex="2"
            ></Input>
          </li>
        </ul>
        <span v-if="editShow !== 2" slot="footer" class="dialog-footer">
          <Button class="exitBt" @click="addUserShow = false">取 消</Button>
          <LevelButton v-show="editShow === 0" :buttonFlag="buttonFlag" btnTitle="确 定"  size="middle" :auth="'BASIC:SYSTEM:ROLES/ADD'" @confirm="onSubmit" @close="addUserShow = false"></LevelButton>
          <LevelButton v-show="editShow !== 0" :buttonFlag="buttonFlag" btnTitle="确 定" size="middle" :auth="'BASIC:SYSTEM:ROLES/EDIT'" @confirm="onSubmit" @close="addUserShow = false"></LevelButton>
        </span>
      </Modal>
    </div>
  </div>
</template>
<script>
import {
  getRoles,
  getRolesDetail,
  setRoles,
  delRoles,
} from "@/api/management/index.js";
import { roles } from "@/utils/roles.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      userInfo:{},
      addUserShow: false,
      roleLists: roles,
      defaultRole: [],
      formData: {
        roleId: 0,
        roleName: "",
        roleDesc: "",
        resources: [],
      },
      query: {
        keyword: "",
      },
      roleDisable: false,
      roleProps: {
        label: "title",
        children: "child",
      },
      editShow: 0, // 0 新增 1 修改 2 查看
      buttonFlag:false,
      newsRoleLists:[],

      isLoading: false,
      tableColumns: [
        { title: '创建时间', key: 'createTime', align: 'center' },
        { title: '角色名称', key: 'roleName', align: 'center' },
        { title: '角色概述', key: 'roleDesc', align: 'center' },
        { title: '权限明细', key: 'auth', align: 'center', slot: 'auth' },
        { title: '操作', key: 'action', align: 'center', slot: 'action' }
      ],
      tableData: []
    };
  },
  computed: {
    ...mapState({
      roleQuery: "system_roleQuery",
    }),
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if(this.roleQuery) {
      this.query = this.roleQuery.query;
    }
  },
  mounted() {
    this.getLists();
  },
  methods: {
    ...mapMutations({
      setRoleQuery: "system_setRoleQuery",
    }),
    getLists() {
      this.isLoading = true
      this.query.keyword = this.query.keyword.trim();
      getRoles(this.query).then((res) => {
        this.isLoading = false
        if (res) {
          this.tableData = res.resultData;
        }
      });
    },
    // 修改、查看
    getDetail(value, bool = false) {
      getRolesDetail(value.roleId).then((res) => {
        if (res) {
          this.roleDisable = bool ? true : false;
          this.formData = res.resultData;

          this.defaultRole = this.roleFilter(
            this.formData.resources.split(";"),
            this.roleLists
          );
          if (bool) {
            this.roleLists = JSON.parse(
              JSON.stringify(this.roleLists).replace(/false/g, true)
            );
          } else {
            this.roleLists = JSON.parse(
              JSON.stringify(this.roleLists).replace(/true/g, false)
            );
          }
          this.editShow = bool ? 2 : 1;
          this.addUserShow = true;
        }
      });
    },
    // 新增确认
    addShow() {
      this.formData = {
        roleId: 0,
        roleName: "",
        roleDesc: "",
        resources: [],
      };
      this.editShow = 0;
      this.roleDisable = false;
      this.roleLists = JSON.parse(
        JSON.stringify(this.roleLists).replace(/true/g, false)
      );
      this.defaultRole = [];
      this.addUserShow = true;
      this.$nextTick(() => {
        this.$refs.menuTree.store.nodesMap["BASIC"].expanded = true;
      })
    },
    // 提交
    onSubmit() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.roleName) {
        this.$Message.error("角色名称必填");
        return;
      }
      if (data.resources.length < 1) {
        this.$Message.error("角色权限必选");
        return;
      }
      this.buttonFlag = true;
      setRoles(data).then((res) => {
        if (res.resultStatus) {
          this.addUserShow = false;
          this.buttonFlag = false;
          this.$Message.success("保存成功");
          this.getLists();         
        }
      });
    },
    //删除角色
    delRole(value) {
      this.$confirm({
        isShow: true,
        message: '此操作将删除该角色, 是否继续?',
      }).then(() => {
        delRoles(value.roleId).then((res) => {
          if (res) {
            this.getLists()
            this.$Message.success('删除成功!')
          }
        });
      }).catch(() => {
        this.$Message.warning('已取消删除')
      })
    },
    // 权限改变
    roleChange(e, list) {
      let roleList = list.checkedKeys.concat(list.halfCheckedKeys).join(";");
      this.formData.resources = roleList;
    },
    // 权限筛选
    roleFilter(roleList, roleLists) {
      let arr = [];
      roleLists.forEach((v) => {
        let child = roleList.find((res) => res === v.value) ? v.child : false;
        if (child) {
          arr = arr.concat(this.roleFilter(roleList, child));
          let reData = this.roleFilter(roleList, child);
          let reNum = child.filter((res) => reData.indexOf(res.value) !== -1);
          if (reNum.length !== child.length) {
            return arr;
          }
        }
        arr.push(roleList.find((res) => res === v.value));
        arr = arr.filter((res) => res !== undefined);
      });
      return arr;
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setRoleQuery(data);
  },
};
</script>
<style lang="less" scoped>
.addDetailBt {
  background-color: #fff;
  color: var(--themeColor);
  line-height: 1;
  cursor: pointer;
  border: 1px solid #DCDFE6;
  color: #606266;
  text-align: center;
  outline: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
}

.jianju2 {
  display: inline-block;
  width: 51px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;     
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}

.overAccount_ul /deep/ .ivu-input,
.overAccount_ul /deep/ .ivu-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
}

.ul-right {
  float: right;
  width: 200px;
}

.role {
  width: 490px;
  margin-left: 13px;
}

.role .el-checkbox {
  color: var(--label);
  margin-right: 0;
  margin-bottom: 12px;
}

.role .h1 {
  float: left;
  width: 159px;
}

.role .h2 {
  float: left;
  width: 174px;
}

.role .h3 {
  float: left;
}

.el-pagination {
  margin-bottom: 15px;
}

.el-tree {
  width: 439px;
}
</style>
