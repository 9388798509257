let role =  [
  {
    title: '服务商助手',
    value: 'BASIC',
    disabled: true,
    child: [{
      title: '档案管理',
      value: 'BASIC:FILEMANA',
      disabled: true,
      child: [
        {
          id: "1",
          title: '服务商信息',
          value: 'BASIC:FILEMANA:SERVICEINFO',
          path: '/',
          disabled: true,
          child: [{
            title: '基本信息',
            value: 'BASIC:FILEMANA:SERVICEINFO/INFO',
            lv: '1',
            disabled: true,
          }, {
            title: '银行信息',
            value: 'BASIC:FILEMANA:SERVICEINFO/BANKINFO',
            lv: '1',
            disabled: true,
          }, {
            title: '配置信息',
            value: 'BASIC:FILEMANA:SERVICEINFO/CONFIG',
            lv: '1',
            disabled: true,
          }]
        },
        {
          id: "2",
          title: '商户列表',
          value: 'BASIC:FILEMANA:BUSINESSLIST',
          path: '/fileManage/businessList',
          disabled: true,
          child: [{
            title: '查看',
            value: 'BASIC:FILEMANA:BUSINESSLIST/SHOW',
            lv: '1',
            disabled: true,
          }, {
            title: '新增',
            value: 'BASIC:FILEMANA:BUSINESSLIST/ADD',
            lv: '1',
            disabled: true,
          }, {
            title: '详情',
            value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL',
            lv: '1',
            disabled: true,
            child: [{
              title: '基本信息',
              value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO',
              lv: '1',
              disabled: true,
              child: [{
                title: '查看',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/SHOW',
                lv: '1',
                disabled: true,
              }, {
                title: '编辑',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/EDIT',
                lv: '1',
                disabled: true,
              }, {
                title: '重发邮件',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/REMAIL',
                lv: '1',
                disabled: true,
              }, {
                title: '通过',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/PASS',
                lv: '1',
                disabled: true,
              }, {
                title: '驳回',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/REJECT',
                lv: '1',
                disabled: true,
              }, {
                title: '激活',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/ACTIVATE',
                lv: '1',
                disabled: true,
              }, {
                title: '冻结',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/FROZEN',
                lv: '1',
                disabled: true,
              }, {
                title: '关闭',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/CLOSED',
                lv: '1',
                disabled: true,
              }]
            }, {
              title: '结算信息',
              value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/SETTLE',
              lv: '1',
              disabled: true
            }, {
              title: '关联分账方',
              value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT',
              lv: '1',
              disabled: true,
              child: [{
                title: '查看',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/SHOW',
                lv: '1',
                disabled: true,
              }, {
                title: '编辑',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/EDIT',
                lv: '1',
                disabled: true,
              }]
            }, {
              title: '电子账簿',
              value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK',
              lv: '1',
              disabled: true,
              child: [{
                title: '查看',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/SHOW',
                lv: '1',
                disabled: true,
              }, {
                title: '编辑',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT',
                lv: '1',
                disabled: true,
              }, {
                title: '删除',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/DELETE',
                lv: '1',
                disabled: true,
              }]
            }, {
              title: '产品信息',
              value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/PRODUCT',
              lv: '1',
              disabled: true
            }, {
              title: '补充材料',
              value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ATTACH',
              lv: '1',
              disabled: true,
              child: [{
                title: '查看',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ATTACH/SHOW',
                lv: '1',
                disabled: true,
              }, {
                title: '编辑',
                value: 'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ATTACH/EDIT',
                lv: '1',
                disabled: true,
              }]
            }]
          }]
        },
        {
          id: "3",
          title: '分账方列表',
          value: 'BASIC:FILEMANA:ACCOUNTLIST',
          path: '/fileManage/accountList',
          disabled: true,
          child: [{
            title: '查看',
            value: 'BASIC:FILEMANA:ACCOUNTLIST/SHOW',
            lv: '1',
            disabled: true,
          }, {
            title: '新增',
            value: 'BASIC:FILEMANA:ACCOUNTLIST/ADD',
            lv: '1',
            disabled: true,
          }, {
            title: '详情',
            value: 'BASIC:FILEMANA:ACCOUNTLIST/DETAIL',
            lv: '1',
            disabled: true,
            child: [{
              title: '查看',
              value: 'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/SHOW',
              lv: '1',
              disabled: true,
            }, {
              title: '编辑',
              value: 'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/EDIT',
              lv: '1',
              disabled: true,
            }, {
              title: '商户签约',
              value: 'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/SIGN',
              lv: '1',
              disabled: true,
            }, {
              title: '同步信息',
              value: 'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/SYNC',
              lv: '1',
              disabled: true,
            }, {
              title: '上传图片',
              value: 'BASIC:FILEMANA:ACCOUNTLIST/DETAIL/UPLOAD',
              lv: '1',
              disabled: true,
            }]
          }]
        }
      ]
    },
    {
      title: '余额账户管理',
      value: 'BASIC:BALANCE',
      disabled: true,
      child: [{
        id: "1",
        title: '余额账户列表',
        value: 'BASIC:BALANCE:LIST',
        path: '/balanceList/balance_list',
        disabled: true,
        child: [{
          title: '查看',
          value: 'BASIC:BALANCE:LIST/SHOW',
          lv: '1',
          disabled: true,
        },{
          title: '查询',
          value: 'BASIC:BALANCE:LIST/SEARCH', 
          lv: '1',
          disabled: true,
        },{
          title: '转账',
          value: 'BASIC:BALANCE:LIST/TRANSFER', 
          lv: '1',
          disabled: true,
        },{
          title: '结算',
          value: 'BASIC:BALANCE:LIST/SETTLEMENT',
          lv: '1',
          disabled: true,
        }]
      },{
        id: "2",
        title: '入账查询',
        value: 'BASIC:BALANCE:ENTRYSEAR',
        path: '/entryInquiry/entryInquiry_sear',
        disabled: true,
        child: [{
          title: '查看',
          value: 'BASIC:BALANCE:ENTRYSEAR/SHOW',
          lv: '1',
          disabled: true,
        } , {
          title: '详情',
          value: 'BASIC:BALANCE:ENTRYSEAR/DETAIL', 
          lv: '1',
          disabled: true,
        } , {
          title: '导出',
          value: 'BASIC:BALANCE:ENTRYSEAR/EXPORT',
          lv: '1',
          disabled: true,
        }]
      },{
        id: "3",
        title: '转账查询',
        value: 'BASIC:BALANCE:TRANSFER',
        path: '/transferSear/transfer_sear',
        disabled: true,
        child: [{
          title: '查看',
          value: 'BASIC:BALANCE:TRANSFER/SHOW',
          lv: '1',
          disabled: true,
        }, {
          title: '详情',
          value: 'BASIC:BALANCE:TRANSFER/DETAIL',
          lv: '1',
          disabled: true,
        }, {
          title: '导出',
          value: 'BASIC:BALANCE:TRANSFER/EXPORT',
          lv: '1',
          disabled: true,
        } , {
          title: '同步',
          value: 'BASIC:BALANCE:TRANSFER/SYNC',
          lv: '1',
          disabled: true,
        } , {
          title: '明细',
          value: 'BASIC:BALANCE:TRANSFER/MINUTIA',
          disabled: false,
        } ,
      ]
      },{
        id: "4",
        title: '结算查询',
        value: 'BASIC:BALANCE:SETTLE',
        path: '/settleSear/settle_sear',
        disabled: true,
        child: [{
          title: '查看',
          value: 'BASIC:BALANCE:SETTLE/SHOW',
          lv: '1',
          disabled: true,
        }, {
          title: '详情',
          value: 'BASIC:BALANCE:SETTLE/DETAIL',
          lv: '1',
          disabled: true,
        }, {
          title: '同步',
          value: 'BASIC:BALANCE:SETTLE/SYNC',
          lv: '1',
          disabled: true,
        }, {
          title: '导出',
          value: 'BASIC:BALANCE:SETTLE/EXPORT',
          lv: '1',
          disabled: true,
        }
      ]
      },]
    },
    {
      title: '系统管理',
      value: 'BASIC:SYSTEM',
      disabled: true,
      child: [{
        title: '角色管理',
        path: '/system/role_jsgl',
        value: 'BASIC:SYSTEM:ROLES',
        lv: '1',
        disabled: true,
        child: [{
          title: '查看',
          value: 'BASIC:SYSTEM:ROLES/SHOW',
          lv: '1',
          disabled: true,
        }, {
          title: '新增',
          value: 'BASIC:SYSTEM:ROLES/ADD',
          lv: '1',
          disabled: true,
        }, {
          title: '编辑',
          value: 'BASIC:SYSTEM:ROLES/EDIT',
          lv: '1',
          disabled: true,
        }, {
          title: '删除',
          value: 'BASIC:SYSTEM:ROLES/DELETE',
          lv: '1',
          disabled: true,
        }]
      }, {
        title: '员工管理',
        path: '/system/staff_yggl',
        value: 'BASIC:SYSTEM:EMPLOYEES',
        lv: '1',
        disabled: true,
        child: [{
          title: '查看',
          value: 'BASIC:SYSTEM:EMPLOYEES/SHOW',
          lv: '1',
          disabled: true,
        }, {
          title: '新增',
          value: 'BASIC:SYSTEM:EMPLOYEES/ADD',
          lv: '1',
          disabled: true,
        }, {
          title: '编辑',
          value: 'BASIC:SYSTEM:EMPLOYEES/EDIT',
          lv: '1',
          disabled: true,
        }, {
          title: '删除',
          value: 'BASIC:SYSTEM:EMPLOYEES/DELETE',
          lv: '1',
          disabled: true,
        }]
      }, {
        title: '安全设置',
        path: '/system/security_aqsz',
        value: 'BASIC:SYSTEM:SAFE',
        lv: '1',
        disabled: true,
        child: [{
          title: '查看',
          value: 'BASIC:SYSTEM:SAFE/SHOW',
          lv: '1',
          disabled: true,
        }, {
          title: '绑定手机-修改',
          value: 'BASIC:SYSTEM:SAFE/EDIT',
          lv: '1',
          disabled: true,
        }, {
          title: '绑定邮箱-修改',
          value: 'BASIC:SYSTEM:SAFE/EDITEMAIL',
          lv: '1',
          disabled: true,
        },{
          title: '安全密码-修改',
          value: 'BASIC:SYSTEM:SAFE/EDITAQMM',
          lv: '1',
          disabled: true,
        }, {
          title: '安全密码-设置',
          value: 'BASIC:SYSTEM:SAFE/SETAQMI',
          lv: '1',
          disabled: true,
        }, {
          title: '预留信息-修改',
          value: 'BASIC:SYSTEM:SAFE/EDITYLXX',
          lv: '1',
          disabled: true,
        }, {
          title: '预留信息-设置',
          value: 'BASIC:SYSTEM:SAFE/SET',
          lv: '1',
          disabled: true,
        }, {
          title: '找回',
          value: 'BASIC:SYSTEM:SAFE/RETRIEVE',
          lv: '1',
          disabled: true,
        },]
      },]
    }]
  }
]

export const roles = role